<template>
    <section
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Reviews</div>
                </div>
            </div>
        </div>

        <div class="c-reviews" :class="[{ 'has-border': componentData.settings.enable_border }]">
            <EventReviews
            v-if="park && park.acf.tripadvisor.tripadvisor_widget"
            :tripadvisor="park.acf.tripadvisor.tripadvisor_widget"
            :tripadvisor_script="park.acf.tripadvisor.tripadvisor_script" />
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const config = useRuntimeConfig();
    const park = ref(null);

    const getPark = async (parkId = 9) => {
        // console.log(parkId);

        const { data } = await useFetch(`/wp-json/wp/v2/holgate-park/${parkId}`, {
            baseURL: config.public.cmsBaseUrl,
            query: {
                acf_format: "standard",
            },
        });

        // console.log("Park Data: ", data.value);

        park.value = data.value;
    };

    await getPark(props.componentData.park.ID);

    if (park.value.acf.tripadvisor.tripadvisor_script) {
        useHead({
            script: [
                {
                    src: park.value.acf.tripadvisor.tripadvisor_script,
                    async: true,
                    "data-loadtrk": true,
                },
            ],
        });
    }

    // console.log("Component Data: ", props.componentData);
</script>
